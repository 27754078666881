export enum JobScreenerQuestionPresets {
  HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR = "HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR",
}
export enum AutoRejectResponses {
  YES = "YES",
  NO = "NO",
}

export type JobScreenerQuestionPopulatedValue =
  | {
      type: "YES_OR_NO";
      question: string;
      autoReject?: [AutoRejectResponses];
    }
  | {
      type: JobScreenerQuestionPresets;
      autoReject?: [AutoRejectResponses];
    };

export type JobScreenerQuestionValue =
  | {
      type: null; // not set yet
      autoReject?: [AutoRejectResponses];
    }
  | JobScreenerQuestionPopulatedValue;
