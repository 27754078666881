import { uniq } from "lodash";

import { ModalErrorState } from "@rewards-web/shared/components/modal/modal-error-state";
import { ModalLoadingState } from "@rewards-web/shared/components/modal/modal-loading-state";
import { JobPostingFieldType } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { RightDrawer } from "../../../../../components/right-drawer";
import { useMyBranches } from "../../../../branches/use-my-branches";
import { useHasPermissionQuery } from "../../../../permissions/hooks/use-has-permission-query";
import { serializeScreenerQuestions } from "../../../lib/serialize-screener-questions-field-configuration";
import { EditJobForm, EditJobFormValues } from "./edit-job-form";
import { useJobPostingToEditQuery } from "./job-posting-to-edit.generated";
import { useUpdateJobPostingMutation } from "./update-job-posting.generated";

interface EditJobRightDrawerProps {
  open: boolean;
  jobPostingId?: string;
  onClose(): void;
  onUpdated(): void;
  onExited(): void;
  saveDescription: string;
}

export function EditJobRightDrawer({
  open,
  jobPostingId,
  onClose,
  onUpdated,
  onExited,
  saveDescription,
}: EditJobRightDrawerProps) {
  const track = useTrack();
  const snackbar = useSnackbar();
  const fullJobsPermissionsQuery = useHasPermissionQuery("full", "jobs");
  const readOnly = !fullJobsPermissionsQuery.hasPermission;

  const jobPostingQuery = useJobPostingToEditQuery({
    variables: {
      jobPostingId: jobPostingId!,
    },
    skip: !jobPostingId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onError: reportError,
  });

  const myOrganizationBranchesQuery = useMyBranches();

  const [
    updateJobPosting,
    { loading: updatingJobPosting },
  ] = useUpdateJobPostingMutation();

  const onSubmit = async (values: EditJobFormValues) => {
    try {
      if (!jobPostingQuery.data?.getJobPostingById) {
        throw new Error("Invariant; job posting is not available");
      }

      const res = await updateJobPosting({
        variables: {
          jobPostingId: jobPostingId!,
          branchId: values.branchId || null,
          title: values.jobTitle,
          hoursRequired: values.hoursRequired,
          ratesOfPay: values.rateOfPay || null,
          geography: values.location,
          descriptionHTML: values.jobDescription,
          candidateAlertEmail: values.candidateAlertEmail,
          interviewBookingLink: values.interviewBookingLinkChecked
            ? {
                url: values.interviewBookingLink,
                mustUploadResumeBeforeBookingInterview:
                  values.mustUploadResumeBeforeBookingInterview === "YES",
              }
            : null,
          customizedApplicantEmailMessage: values.customizedEmailMessageChecked
            ? values.customizedEmailMessage
            : null,
          customizedApplicantSMSMessage: values.customizedSMSMessageChecked
            ? values.customizedSMSMessage
            : null,
          fieldConfiguration: [
            // keep all existing fields since they aren't editable by this form
            ...jobPostingQuery.data.getJobPostingById.applicationFields
              .filter(
                (existingField) =>
                  existingField.type !== JobPostingFieldType.YesOrNo &&
                  existingField.type !==
                    JobPostingFieldType.HasDriversLicenseAndAccessToCar
              )
              .map((existingField) => ({
                type: existingField.type,
                optional:
                  (existingField as { optional?: boolean }).optional ?? false,
              })),

            // and add our screener questions to the end
            ...serializeScreenerQuestions(values.screenerQuestions),
          ],
        },
      });

      onUpdated();
      onClose();

      snackbar.show({
        severity: "success",
        message: "The changes have been saved",
      });
      track("Edited job posting", {
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
        jobPostingId,
      });
    } catch (error) {
      reportError(error);

      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const content = (() => {
    if (jobPostingQuery.loading || myOrganizationBranchesQuery.loading) {
      return <ModalLoadingState />;
    }

    if (
      jobPostingQuery.error ||
      !jobPostingQuery.data?.getJobPostingById ||
      myOrganizationBranchesQuery.error
    ) {
      return <ModalErrorState onClose={onClose} />;
    }

    return (
      <EditJobForm
        onSubmit={onSubmit}
        onClose={onClose}
        readOnly={readOnly}
        jobPosting={jobPostingQuery.data.getJobPostingById}
        saveDescription={saveDescription}
        adminEmailOptions={uniq(
          [
            jobPostingQuery.data.getMyRewardsOrganization
              .defaultCandidateAlertEmail,
            ...jobPostingQuery.data.listUniqueCandidateAlertEmails!.emails,
          ].filter((email): email is string => !!email)
        ).sort()}
        organizationBranches={
          myOrganizationBranchesQuery.data?.getMyRewardsOrganization.branches ??
          []
        }
      />
    );
  })();

  return (
    <RightDrawer
      maxWidth="450px"
      title={readOnly ? "View Job Posting" : "Edit Job Posting"}
      open={open}
      onClose={onClose}
      onExited={onExited}
      disableClose={updatingJobPosting}
    >
      {content}
    </RightDrawer>
  );
}
