import {
  JobPostingAutoRejectResponse,
  JobPostingScreenerQuestionType,
} from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import {
  JobScreenerQuestionPopulatedValue,
  JobScreenerQuestionPresets,
  JobScreenerQuestionValue,
  AutoRejectResponses,
} from "../typings";

export function serializeJobScreenerQuestions(
  screenerQuestions: JobScreenerQuestionValue[]
) {
  return (
    screenerQuestions
      .filter(
        (
          screenerQuestion
        ): screenerQuestion is JobScreenerQuestionPopulatedValue =>
          screenerQuestion.type !== null && screenerQuestion.type !== undefined
      )
      /* eslint-disable array-callback-return */
      .map((screenerQuestion) => {
        const convertAutoRejectResponses = (
          autoReject: [AutoRejectResponses] | undefined
        ): JobPostingAutoRejectResponse[] => {
          return autoReject
            ? autoReject.map((response) => {
                switch (response) {
                  case AutoRejectResponses.YES:
                    return JobPostingAutoRejectResponse.Yes;
                  case AutoRejectResponses.NO:
                    return JobPostingAutoRejectResponse.No;
                  default: {
                    assertNever(response, `Unhandled responses: ${response}`);
                  }
                }
              })
            : [];
        };

        const getBaseObject = (
          screenerQuestion: JobScreenerQuestionPopulatedValue
        ) => {
          switch (screenerQuestion.type) {
            case JobScreenerQuestionPresets.HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR:
              return {
                type:
                  JobPostingScreenerQuestionType.HasDriversLicenseAndAccessToCar,
              };
            case "YES_OR_NO":
              return {
                type: JobPostingScreenerQuestionType.YesOrNo,
                question: screenerQuestion.question,
              };
            default:
              assertNever(screenerQuestion);
          }
        };

        const baseObject = getBaseObject(screenerQuestion);

        return {
          ...baseObject,
          ...(screenerQuestion.autoReject
            ? {
                autoRejectResponses: convertAutoRejectResponses(
                  screenerQuestion.autoReject
                ),
              }
            : {}),
        };
      })
  );
}
